import { fetchProjects } from "../api/projects"
import { throwError } from "../api/utils";

export const loadProject = async (params) => {
    const { accountSlug, projectName, stageName } = params.params
    const projects = await fetchProjects(accountSlug, projectName, stageName)
    if (projects.length !== 1) {
        throwError(`Project with name ${projectName} not found.`, 404);
    }
    return projects[0];
}