import React from "react";
import App from './App'
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FetchTracesQueryProvider } from './viewer/contexts/FetchTracesQueryContext'
import { fetchTracesQuery } from "./queries/traces"

const queryClient = new QueryClient()

function Providers() {
    return (
        <QueryClientProvider client={queryClient}>
            <FetchTracesQueryProvider value={fetchTracesQuery}>
                <RouterProvider router={App} />
            </FetchTracesQueryProvider>
        </QueryClientProvider>
    );
}

export default Providers;